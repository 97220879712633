@import-normalize;

@font-face {
  font-family: 'Graphik Compact';
  src: local('Graphik Compact Light'),
       local('Graphik-Compact-Light'),
       url('../assets/fonts/GraphikCompact-Light-Web.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Compact';
  src: local('Graphik Compact SemiBold'),
       local('Graphik-Compact-SemiBold'),
       url('../assets/fonts/GraphikCompact-Semibold-Web.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal'),
       local('Le-Monde-Journal'),
       url('../assets/fonts/LeMondeJournalWeb-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal Italic'),
       local('Le-Monde-Journal-Italic'),
       url('../assets/fonts/LeMondeJournalWeb-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal Demi'),
       local('Le-Monde-Journal-Demi'),
       url('../assets/fonts/LeMondeJournalWeb-Demi.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal DemiItalic'),
       local('Le-Monde-Journal-DemiItalic'),
       url('../assets/fonts/LeMondeJournalWeb-DemiItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal Bold'),
       local('Le-Monde-Journal-Bold'),
       url('../assets/fonts/LeMondeJournalWeb-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Le Monde Journal';
  src: local('Le Monde Journal BoldItalic'),
       local('Le-Monde-Journal-BoldItalic'),
       url('../assets/fonts/LeMondeJournalWeb-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cadiz Regular';
  src: local('Cadiz Regular'),
       local('Cadiz-Regular'),
       url('../assets/fonts/Cadiz-Regular.otf') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Cadiz Bold';
  src: local('Cadiz Bold'),
       local('Cadiz-Bold'),
       url('../assets/fonts/Cadiz-Bold.otf') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Baskerville';
  src: local('ITC-New-BaskervilleStd-Bold'),
       local('ITC-New-BaskervilleStd-Bold'),
       url('../assets/fonts/NewBaskervilleStd-Bold.otf') format('woff2');
  font-display: swap;
}

html,
body {
  height: 100%;
}

body {
  /*font-family: 'Le Monde Journal';*/
  font-family: 'Cadiz Regular', 'arial';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.error {
  color: red;
  font-size: 80%;
}

.disabled {
  pointer-events: none;
}

input:not([type="submit"]),
input:not([type="button"]),
select {
  font-family: 'Cadiz Regular', 'arial';
  font-weight: 400;
}

a:hover {
  filter: brightness(0.5);
}

/* OM XD Exports, TODO make better */
:root {
/* Colors: */
--color-om-basic: #E0F8F6;
--unnamed-color-c2f2ee: #C2F2EE;
--unnamed-color-a4ece5: #A4ECE5;
--unnamed-color-85e5dd: #85E5DD;
--unnamed-color-67dfd4: #67DFD4;
--unnamed-color-49d9cc: #49D9CC;
--unnamed-color-d4f1fc: #D4F1FC;
--unnamed-color-aae3fa: #AAE3FA;
--unnamed-color-7fd6f8: #7FD6F8;
--unnamed-color-55c8f6: #55C8F6;
--unnamed-color-2abaf4: #2ABAF4;
---00adf2_vene: #00ADF2;
--unnamed-color-ffffff: #FFFFFF;
--unnamed-color-313131: #313131;
--unnamed-color-000000: #000000;

/* Font/text values */
--unnamed-font-family-arial: Arial;
--unnamed-font-family-oswald: Oswald;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-bold: bold;
--unnamed-font-weight-normal: normal;
--unnamed-font-size-12: 12px;
--unnamed-font-size-14: 14px;
--unnamed-font-size-16: 16px;
--unnamed-font-size-20: 20px;
--unnamed-character-spacing-0: 0px;
--unnamed-character-spacing-0-7: 0.7px;
--unnamed-line-spacing-12: 12px;
--unnamed-line-spacing-14: 14px;
--unnamed-line-spacing-18: 18px;
--unnamed-line-spacing-30: 30px;
--unnamed-text-transform-uppercase: uppercase;
}

/* Character Styles */
.om_versaali_arial-—-14pt {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-18);
letter-spacing: var(--unnamed-character-spacing-0-7);
color: var(--unnamed-color-313131);
text-transform: var(--unnamed-text-transform-uppercase);
}
.vene_oswald-—-20pt {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-313131);
}
.vene__oswald-—-16pt {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-18);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-313131);
}
.vene_versaali_oswald-—-14pt {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-18);
letter-spacing: var(--unnamed-character-spacing-0-7);
color: var(--unnamed-color-313131);
text-transform: var(--unnamed-text-transform-uppercase);
}
.vene_oswald-—-16pt_painiketeksti {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-12);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffffff);
}
.vene_oswald-—-14pt {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-18);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.vene_oswald-—-12pt {
font-family: var(--unnamed-font-family-oswald);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-14);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.unnamed-character-style-1 {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-20);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.unnamed-character-style-2 {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-30);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.arial-—-16pt_painiketeksti {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-12);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.unnamed-character-style-3 {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-18);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}
.unnamed-character-style-4 {
font-family: var(--unnamed-font-family-arial);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-normal);
font-size: var(--unnamed-font-size-12);
line-height: var(--unnamed-line-spacing-14);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
}

.page {

  box-sizing: border-box;
  box-shadow: 4px 6px 8px #00000029;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 2em auto;
  width: 100%;
  padding: 1em;
  text-align: center;

}

@media (min-width: 769px) {
  .page {
    max-width: 800px;
  }
}

label {

  margin: 0.2em 0;
  font-size: 12px;

}

.marg-top-mid {
  margin-top: 2em !important;
}