#root,
.app {
  background: #f4f4f4;
  min-height: 100%;
}

h1 {
  font-family: 'Baskerville';
  font-weight: 600;
  font-size: 24px;
  margin-top: 0;
}

.page-content {
  padding: 20px;
  box-sizing: border-box;
}

.form-error {
  font-size: 75%;
  color: red;
  text-align: center;
}