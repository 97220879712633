.app-header {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  padding-bottom: 34px;
}

.nav-primary {
  padding: 15px;
  flex: 2;
}

.nav-primary a {
  display: inline-block;
  margin: 0 10px;
  color: #2e2e2e;
}

.nav-primary a:hover,
.nav-primary a.active {
  color: #326aa5;
}

.nav-primary button {
  float: right;
}

@media screen and (min-width: 768px) {
  .app-header {
    padding: 12px 70px 5px;
    flex-flow: row;
    text-align: unset;
  }
}