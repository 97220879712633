.button-back button {
    border: none;
    cursor: pointer;
    height: 20px;
    margin-left: 0;
    padding-left: 0;
    width: unset;
}

.button-back button:disabled {
  background-color: unset;
}

.button-back button:hover {
    box-shadow: none;
}


@media screen and (min-width: 768px) {
    .button-back button {
      margin-left: 5px;
      margin-bottom: 10px;
      padding-left: 6px;
    }
  }