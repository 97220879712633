.logo {
  padding: 10px 10px 3px;
}

.logo img {
  width: auto;
  max-width: 300px;
  height: 40px;
  border: 0;
}
